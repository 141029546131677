<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-col :span="12">
                    <el-form-item
                        prop="storeId"
                        label="所属门店"
                        label-width="10em"
                    >
                        <RemoteSelect
                            filterable
                            remote="/sp/shopStore/data"
                            placeholder="请选择"
                            :props="{id:'id',name:'storeName'}"
                            v-model="formModel.storeId"
                            disabled-cache
                        />
                    </el-form-item>
                    <el-form-item
                        prop="stationName"
                        label="小区名称"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.stationName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="openTime"
                        label="营业时间"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.openTime"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="stationRegionId"
                        label="小区地址"
                        label-width="10em"
                    >
                        <CascaderPicker
                            filterable
                            :api-class="selectApi"
                            v-model="formModel.stationRegionId"
                            :p-id="2"
                            :min-lv="2"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="stationDetailAddress"
                        label="小区详细地址"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.stationDetailAddress"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="latLng"
                        label="小区坐标"
                        label-width="10em"
                    >
                        <LatLngPicker v-model="formModel.latLng"/>
                    </el-form-item>
                    <el-form-item
                        prop="contactFullname"
                        label="联系人姓名"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.contactFullname"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="contactTel"
                        label="联系人电话"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.contactTel"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="orderNum"
                        label="排序"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            v-model.number="formModel.orderNum"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAvailable"
                        label="是否可用"
                        label-width="10em"
                    >
                        <el-switch
                            v-model="formModel.isAvailable"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label-width="10em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopstationEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                storeId: '',
                stationName: '',
                openTime: '',
                stationRegionId: '',
                stationDetailAddress: '',
                contactFullname: '',
                contactTel: '',
                isAvailable: true,
                latLng: '',
                orderNum: '',
            },
            // 表单校验规则
            formRules: {
                stationName: {
                    required: true,
                    message: '请输入小区名称',
                    trigger: 'blur',
                },
                openTime: {
                    required: true,
                    message: '请输入营业时间',
                    trigger: 'blur',
                },
                stationRegionId: {
                    required: true,
                    message: '请选择小区地址',
                    trigger: 'blur',
                },
                stationDetailAddress: {
                    required: true,
                    message: '请输入小区详细地址',
                    trigger: 'blur',
                },
                contactFullname: {
                    required: true,
                    message: '请输入联系人姓名',
                    trigger: 'blur',
                },
                contactTel: {
                    required: true,
                    message: '请输入联系人电话',
                    trigger: 'blur',
                },
                isAvailable: {
                    required: true,
                    message: '请选择是否可用',
                },
                storeId: {
                    required: true,
                    message: '请选择所属门店',
                },
                orderNum: {
                    required: true,
                    message: '请输入排序号',
                    trigger: 'blur',
                },
                latLng: {
                    required: true,
                    message: '请选择小区坐标',
                    trigger: 'blur',
                },
            },
            selectApi: this.$api.Rs.Region,
            // 表格数据
            selectDataList: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sp.ShopStoreServiceStation.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Sp.ShopStoreServiceStation.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
