<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="storeName"
                    label="门店名称"
                >
                    <el-input
                        v-model="queryFormModel.storeName"
                        placeholder="请输入门店名称"
                    />
                </el-form-item>
                <!--                <el-form-item
                                    prop="isAvailable"
                                    label="状态"
                                >
                                    <el-select
                                        v-model="queryFormModel.isAvailable"
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            label="全部"
                                            value=""
                                        />
                                        <el-option
                                            label="启用"
                                            value="1"
                                        />
                                        <el-option
                                            label="禁用"
                                            value="0"
                                        />
                                    </el-select>
                                </el-form-item>-->
                <el-form-item
                    prop="storeStatus"
                    label="门店状态"
                >
                    <dictionaries-picker
                        type="storeStatus"
                        v-model="queryFormModel.storeStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="isDefault"
                    label="是否默认"
                >
                    <el-select
                        v-model="queryFormModel.isDefault"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/shopStore/storemg/shopStore/edit')"
                        v-if="$power(['shopStore:add'])"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="storeName"
                    label="门店名称"
                    min-width="100"
                />
                <el-table-column
                    prop="storeSn"
                    label="门店编号"
                    min-width="100"
                />
                <el-table-column
                    prop="contactFullname"
                    label="联系人姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="contactTel"
                    label="联系人电话"
                    min-width="100"
                />
                <el-table-column
                    label="门店地址"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.storeRegionName }} {{ scope.row.storeDetailAddress }}
                    </template>
                </el-table-column>
                <!--                <el-table-column
                                    label="是否启用"
                                    width="80"
                                >
                                    <template slot-scope="scope">
                                        {{ scope.row.isAvailable ? '是' : '否' }}
                                    </template>
                                </el-table-column>-->
                <el-table-column
                    prop="storeStatusName"
                    label="门店状态"
                    width="80"
                />
                <el-table-column
                    label="是否默认"
                    width="80"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isDefault ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="修改时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/shopStore/storemg/shopStore/edit/' + scope.row.id)"
                            v-if="$power(['shopStore:edit'])"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/shopStore/storemg/shopStore/shopStoreServiceTime/' + scope.row.id)"
                        >
                            自取配送设置
                        </el-button>
                        <!--                        <el-button
                            type="text"
                            size="small"
                            @click="onInitShopStoreGoods(scope.row)"
                            v-if="(scope.row.storeType & 2) !== 0"
                        >
                            初始化商品
                        </el-button>-->
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/shopStore/storemg/shopStore/shopStoreBuyer/' + scope.row.id)"
                            v-if="(scope.row.storeType & 2) !== 0"
                        >
                            导购管理
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/shopStore/storemg/shopStore/shopStoreDevice/' + scope.row.id)"
                            v-if="(scope.row.storeType & 2) !== 0"
                        >
                            设备管理
                        </el-button>
                        <!--<el-button
type="text"
size="small"
@click="$router.push('/shopStore/storemg/shopStore/shopStoreFreight/' + scope.row.id)"
>运费设置
</el-button>-->
                        <!--                        <el-button
                                                    type="text"
                                                    size="small"
                                                    @click="$router.push('/shopStore/storemg/shopStore/storePaymentConfig/' + scope.row.shopId + '/' + scope.row.id)"
                                                >
                                                    支付设置
                                                </el-button>-->
                        <el-button
                            type="text"
                            size="small"
                            @click="onShowShareInfo(scope.row)"
                        >
                            二维码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <el-dialog
            title="二维码"
            center
            width="600px"
            :visible.sync="shareInfoDialog.isVisible"
            @closed="onShareInfoDialogCancel"
        >
            <el-form
                ref="shareInfoForm"
                size="small"
                :model="shareInfoDialog.formModel"
                :rules="shareInfoDialog.formRules"
            >
                <el-form-item
                    label="小程序码"
                    label-width="9em"
                    v-if="shareInfoDialog.formModel.offlineOrderMinAppCodeImage"
                >
                    <img
                        width="200"
                        :src="shareInfoDialog.formModel.offlineOrderMinAppCodeImage"
                        alt=""
                    >
                    <div class="label">线下收款小程序码（右键另存为保存图片）</div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onShareInfoDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'ShopStore',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                storeName: '', // 门店名称
                isAvailable: '', // 门店状态
                storeStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            shareInfoDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    offlineOrderMinAppCodeImage: '',
                    offlineOrderAliMinAppCodeImage: '',
                },
                // 表单校验规则
                formRules: {},
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sp.ShopStore.data({
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onInitShopStoreGoods(row) {
            this.$confirm('是否初始化门店商品！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Sp.ShopStore.initShopStoreGoods({ id: row.id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
        onShowShareInfo(row) {
            const { formModel } = this.shareInfoDialog;
            this.shareInfoDialog.formModel.id = row.id;
            this.$api.Sp.ShopStore.getShareInfo({ id: this.shareInfoDialog.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
                this.shareInfoDialog.isVisible = true;
            });
        },
        onShareInfoDialogCancel() {
            const { shareInfoForm } = this.$refs;
            shareInfoForm.resetFields();
            const { formModel } = this.shareInfoDialog;
            formModel.id = '';
            this.shareInfoDialog.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
</style>
