<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-col :span="24">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-col :span="24">
                        <el-form-item
                            prop="shopStoreLevelId"
                            label="门店等级"
                            label-width="10em"
                        >
                            <RemoteSelect
                                remote="/sp/shopStoreLevel/select"
                                placeholder="请输入"
                                :props="{id:'id',name:'levelName'}"
                                v-model="formModel.shopStoreLevelId"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="storeName"
                            label="门店名称"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.storeName"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="storeSn"
                            label="门店编号"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.storeSn"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="storeLogo"
                            label="门店Logo"
                            label-width="10em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="1"
                                v-model="formModel.storeLogo"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="storeAdImage"
                            label="门店广告图"
                            label-width="10em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="1"
                                v-model="formModel.storeAdImage"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="openTime"
                            label="营业时间描述"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.openTime"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="openTimeType"
                            label="营业时间类型"
                            label-width="10em"
                        >
                            <dictionaries-picker
                                type="openTimeType"
                                v-model="formModel.openTimeType"
                            />
                        </el-form-item>
                        <el-form-item
                            label="营业时间（每天）"
                            label-width="10em"
                            v-if="+formModel.openTimeType === 1"
                        >
                            <el-button
                                type="text"
                                size="small"
                                @click="onAddOpenTime()"
                            >
                                新增
                            </el-button>
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="openTimeList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="time"
                                    label="营业时间"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        <el-time-picker
                                            is-range
                                            v-model="scope.row.time"
                                            range-separator="至"
                                            start-placeholder="开始时间"
                                            end-placeholder="结束时间"
                                            placeholder="选择时间范围"
                                            value-format="timestamp">
                                        </el-time-picker>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    width="90"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onDeleteOpenTime(scope.row)"
                                        >
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item
                            prop="storeNotice"
                            label="门店公告"
                            label-width="10em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.storeNotice"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="storeType"
                            label="门店类型"
                            label-width="10em"
                        >
                            <checkbox-group-andOr
                                type="storeType"
                                v-model="formModel.storeType"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="pickupType"
                            label="自取类型"
                            label-width="10em"
                            v-if="formModel.storeType & 1"
                        >
                            <checkbox-group-andOr
                                type="pickupType"
                                v-model="formModel.pickupType"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="storeDesc"
                            label="门店简介"
                            label-width="10em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.storeDesc"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="storeRegionId"
                            label="门店地址"
                            label-width="10em"
                        >
                            <CascaderPicker
                                filterable
                                :api-class="selectApi"
                                v-model="formModel.storeRegionId"
                                :p-id="0"
                                :min-lv="1"
                                :query-params="{minLv:1}"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="storeDetailAddress"
                            label="门店详细地址"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.storeDetailAddress"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="latLng"
                            label="门店坐标"
                            label-width="10em"
                        >
                            <LatLngPicker v-model="formModel.latLng"/>
                        </el-form-item>
                        <el-form-item
                            prop="contactFullname"
                            label="门店联系姓名"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.contactFullname"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="contactTel"
                            label="门店联系电话"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.contactTel"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="storeContactTel"
                            label="联系人电话"
                            label-width="10em"
                        >
                            <el-input
                                v-model="formModel.storeContactTel"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <tempalte v-if="(formModel.storeType & 2) || (formModel.storeType & 4)">
                            <el-form-item
                                prop="speakerImei"
                                label="音箱编码"
                                label-width="10em"
                            >
                                <el-input
                                    v-model="formModel.speakerImei"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="printerSn"
                                label="打印机编码"
                                label-width="10em"
                            >
                                <el-input
                                    v-model="formModel.printerSn"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="isAutoPrint"
                                label="是否自动打印"
                                label-width="10em"
                            >
                                <el-switch
                                    v-model="formModel.isAutoPrint"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="isStoreIncome"
                                label="是否门店代理分润"
                                label-width="10em"
                            >
                                <el-switch
                                    v-model="formModel.isStoreIncome"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="isPushInventory"
                                label="是否推送进销存系统"
                                label-width="10em"
                            >
                                <el-switch
                                    v-model="formModel.isPushInventory"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="inventoryAppKey"
                                label="进销存系统AppKey"
                                label-width="10em"
                                v-if="formModel.isPushInventory"
                            >
                                <el-input
                                    v-model="formModel.inventoryAppKey"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="inventoryAppSecret"
                                label="进销存系统AppSecret"
                                label-width="10em"
                                v-if="formModel.isPushInventory"
                            >
                                <el-input
                                    v-model="formModel.inventoryAppSecret"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="pospalAppId"
                                label="银豹系统appId"
                                label-width="10em"
                                v-if="userData.shop.isSyncPospal"
                            >
                                <el-input
                                    v-model="formModel.pospalAppId"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="pospalAppKey"
                                label="银豹系统appKey"
                                label-width="10em"
                                v-if="userData.shop.isSyncPospal"
                            >
                                <el-input
                                    v-model="formModel.pospalAppKey"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="publicToken"
                                label="对外公共接口TOKEN"
                                label-width="10em"
                            >
                                <el-input
                                    v-model="formModel.publicToken"
                                    auto-complete="off"
                                />
                            </el-form-item>
                        </tempalte>
                        <el-form-item
                            prop="isDefault"
                            label="是否默认"
                            label-width="10em"
                        >
                            <el-switch
                                v-model="formModel.isDefault"
                            />
                        </el-form-item>
                        <!--                        <el-form-item
                                                    prop="isAvailable"
                                                    label="是否可用"
                                                    label-width="10em"
                                                >
                                                    <el-switch
                                                        v-model="formModel.isAvailable"
                                                    />
                                                </el-form-item>-->
                        <el-form-item
                            prop="storeStatus"
                            label="门店状态"
                            label-width="10em"
                            v-if="+formModel.openTimeType === 100"
                        >
                            <dictionaries-picker
                                type="storeStatus"
                                v-model="formModel.storeStatus"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="门店管理员"
                            label-width="10em"
                        >
                            <div
                                class="ma-b"
                            >
                                <el-button-group>
                                    <el-button
                                        type="success"
                                        icon="el-icon-plus"
                                        size="small"
                                        @click="onAddSelect"
                                    >
                                        选择管理员
                                    </el-button>
                                </el-button-group>
                            </div>

                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="selectDataList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="userName"
                                    label="用户名"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="fullName"
                                    label="姓名"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="orgName"
                                    label="部门"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="userStatusName"
                                    label="用户状态"
                                    min-width="100"
                                />
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    width="45"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="onDeleteSelect(scope.row)"
                                        >
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label-width="10em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirm"
                            >
                                保 存
                            </el-button>
                            <el-button
                                size="small"
                                @click="$router.back()"
                            >
                                返 回
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-col>
        </el-row>

        <!-- 弹框 -->
        <el-dialog
            title="新增管理员"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="userName"
                    label="用户名"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.userName"
                        placeholder="请输入用户名"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="userName"
                        label="用户名"
                        min-width="100"
                    />
                    <el-table-column
                        prop="fullName"
                        label="姓名"
                        min-width="100"
                    />
                    <el-table-column
                        prop="orgName"
                        label="部门"
                        min-width="100"
                    />
                    <el-table-column
                        prop="userStatusName"
                        label="用户状态"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="90"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="onSelectConfirm(scope.row)"
                            >
                                选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm(null)"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopStoreEdit',
    mixins: [pagesMixin],
    data() {
        return {
            ShopStoreDetail: {},
            // 表单数据
            formModel: {
                id: '',
                storeName: '',
                storeSn: '',
                storeLogo: [],
                storeAdImage: [],
                openTime: '',
                storeNotice: '',
                storeType: 2,
                pickupType: 1,
                storeDesc: '',
                storeRegionId: '',
                storeDetailAddress: '',
                contactFullname: '',
                contactTel: '',
                storeContactTel: '',
                speakerImei: '',
                printerSn: '',
                isAutoPrint: false,
                isAvailable: true,
                isDefault: false,
                isStoreIncome: false,
                latLng: '',
                shopStoreLevelId: '',
                isPushInventory: false,
                inventoryAppKey: '',
                inventoryAppSecret: '',
                publicToken: '',
                pospalAppId: '',
                pospalAppKey: '',
                storeStatus: 100,
                openTimeType: 100,
            },
            // 表单校验规则
            formRules: {
                inventoryAppKey: {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                },
                inventoryAppSecret: {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                },
                storeName: {
                    required: true,
                    message: '请输入门店名称',
                    trigger: 'blur',
                },
                /* storeLogo: {
                    type: 'array',
                    required: true,
                    message: '请上传门店Logo',
                },
                storeAdImage: {
                    type: 'array',
                    required: true,
                    message: '请上传门店广告图',
                }, */
                openTime: {
                    required: true,
                    message: '请输入营业时间',
                    trigger: 'blur',
                },
                storeType: [
                    {
                        required: true,
                        message: '请选择门店类型',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择门店类型',
                    },
                ],
                pickupType: [
                    {
                        required: true,
                        message: '请选择自取类型',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择自取类型',
                    },
                ],
                storeRegionId: {
                    required: true,
                    message: '请选择门店地址',
                    trigger: 'blur',
                },
                storeDetailAddress: {
                    required: true,
                    message: '请输入门店详细地址',
                    trigger: 'blur',
                },
                contactFullname: {
                    required: true,
                    message: '请输入联系人姓名',
                    trigger: 'blur',
                },
                contactTel: {
                    required: true,
                    message: '请输入联系人电话',
                    trigger: 'blur',
                },
                isAvailable: {
                    required: true,
                    message: '请选择是否可用',
                },
                isDefault: {
                    required: true,
                    message: '请选择是否默认',
                },
                isStoreIncome: {
                    required: true,
                    message: '请选择',
                },
                shopStoreLevelId: {
                    required: true,
                    message: '请选择门店等级',
                },
                latLng: {
                    required: true,
                    message: '请选择门店坐标',
                    trigger: 'blur',
                },
                storeStatus: {
                    required: true,
                    message: '请选择门店状态',
                },
                openTimeType: {
                    required: true,
                    message: '请选择营业时间类型',
                },
            },
            selectApi: this.$api.Rs.Region,
            openTimeList: [
                {
                    time: [1672531200000, 1672581600000],
                },
            ],

            // 新增/编辑
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    userName: '', // 用户名
                    fullName: '', // 姓名
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            // 表格数据
            selectDataList: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    computed: {
        storeId() {
            return this.$route.params.id || '';
        },
        isShopStoreEditPage() {
            return this.$route.name === 'shopStore-storemg-shopStoreEdit';
        },
        shopStore() {
            return this.ShopStoreDetail.shopStore || {};
        },
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                const userIdList = [];
                this.selectDataList.forEach(item => {
                    userIdList.push(item.id);
                });
                this.openTimeList.forEach(item => {
                    [item.beginTime, item.endTime] = item.time;
                });
                this.$api.Sp.ShopStore.save({
                    ...this.formModel,
                    userIds: userIdList.join(','),
                    openTimeJson: JSON.stringify(this.openTimeList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        getDetail() {
            let type = 2;
            const { storeId, isShopStoreEditPage } = this;
            if (isShopStoreEditPage && storeId) type = 1;
            this.formModel.id = storeId;
            if (this.formModel.id) {
                this.$api.Sp.ShopStore.getDetail({ id: this.formModel.id, type }).then(json => {
                    const res = json.data.data;
                    this.ShopStoreDetail = res;
                    this.$utils.formModelMerge(this.formModel, res.shopStore);
                    this.selectDataList = res.shopStoreUserList || [];
                    res.openTimeList.forEach(item => {
                        item.time = [item.beginTime, item.endTime];
                    });
                    this.openTimeList = res.openTimeList || [];
                });
            }
        },
        /* -- 事件 -- */
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
                this.selectDataList.forEach(item => {
                    this.$refs.selectDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Rs.User.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                isShopStoreUser: 0,
                storeIdNE: this.formModel.id,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm(row) {
            if (row) {
                this.selectDataList = [row];
            } else {
                this.selectDataList = [...this.selectDialog.currentTableSelect];
            }
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(row) {
            this.selectDataList.splice(this.selectDataList.indexOf(row), 1);
        },
        onAddOpenTime() {
            this.openTimeList.push({});
        },
        onDeleteOpenTime(row) {
            this.openTimeList.splice(this.openTimeList.indexOf(row), 1);
        },
    },
    created() {
        this.getDetail();
    },
};
</script>

<style lang="scss">
</style>
