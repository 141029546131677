<!-- Created by henian.xu on 2018/7/20. -->
<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="deviceCatId"
                    label="设备类型名称"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.deviceCatId"
                        check-strictly
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceName"
                    label="设备名称"
                >
                    <el-input
                        v-model="queryFormModel.deviceName"
                        placeholder="请输入设备名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceSn"
                    label="设备编号"
                >
                    <el-input
                        v-model="queryFormModel.deviceSn"
                        placeholder="请输入设备编号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        关联设备
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                ref="selectDeviceDialogTable"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="storeName"
                    label="门店名称"
                    min-width="100"
                />
                <el-table-column
                    prop="name"
                    label="设备类型名称"
                    min-width="100"
                />
                <el-table-column
                    prop="deviceName"
                    label="设备名称"
                    min-width="100"
                />
                <el-table-column
                    prop="deviceSn"
                    label="设备编号"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="140"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="cancelRelationDevice(scope.row.id)"
                        >
                            取消关联
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onShowShareInfo(scope.row)"
                        >
                            分享
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            title="关联设备"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="addDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="addDialog.formModel"
            >
                <el-form-item
                    prop="deviceCatId"
                    label="设备类型名称"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="addDialog.formModel.deviceCatId"
                        check-strictly
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceName"
                    label="设备名称"
                >
                    <el-input
                        v-model="addDialog.formModel.deviceName"
                        placeholder="请输入设备名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceSn"
                    label="设备编号"
                >
                    <el-input
                        v-model="addDialog.formModel.deviceSn"
                        placeholder="请输入设备编号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDeviceDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetDeviceAddDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="addDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="addDialogDeviceTable"
                    @selection-change="onAddDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="name"
                        label="设备类型名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="deviceName"
                        label="设备名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="deviceSn"
                        label="设备编号"
                        min-width="100"
                    />
                </el-table>
                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="addDialog.pagination"
                        @input="onSelectDeviceDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    关 联
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            :title="shareInfoDialog.formModel.id ? '分享链接' : '新增'"
            center
            width="600px"
            :visible.sync="shareInfoDialog.isVisible"
            @closed="onShareInfoDialogCancel"
        >
            <el-form
                ref="shareInfoForm"
                size="small"
                :model="shareInfoDialog.formModel"
                :rules="shareInfoDialog.formRules"
            >
                <el-form-item
                    prop="indexShareUrl"
                    label="首页分享链接"
                    label-width="9em"
                >
                    {{ shareInfoDialog.formModel.indexShareUrl }}
                </el-form-item>
                <el-form-item
                    prop="goodsCatShareUrl"
                    label="商品分类分享链接"
                    label-width="9em"
                >
                    {{ shareInfoDialog.formModel.goodsCatShareUrl }}
                </el-form-item>
                <el-form-item
                    prop="goodsShareUrl"
                    label="商品分享链接"
                    label-width="9em"
                >
                    {{ shareInfoDialog.formModel.goodsShareUrl }}
                </el-form-item>
                <el-form-item
                    label="小程序码"
                    label-width="9em"
                >
                    <img
                        width="200"
                        :src="shareInfoDialog.formModel.minAppCodeImage"
                        alt=""
                    >
                    <div class="label">小程序码（右键另存为保存图片）</div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onShareInfoDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'ShopStoreDevice',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                storeId: '',
                deviceCatId: '', // 设备类型名称
                deviceName: '', // 设备名称
                deviceSn: '', // 设备编码
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectApi: this.$api.Sp.DeviceCat,

            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    deviceCatId: '',
                    deviceName: '',
                    deviceSn: '',
                    isAvailable: 1,
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 已选择数据
                currentTableSelect: [],
            },
            shareInfoDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    indexShareUrl: '',
                    goodsCatShareUrl: '',
                    goodsShareUrl: '',
                    minAppCodeImage: '',
                },
                // 表单校验规则
                formRules: {},
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sp.Device.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDeviceDialogQuery();
            });
        },
        // 查询
        onSelectDeviceDialogQuery(pagination) {
            return this.$api.Sp.Device.data({
                ...this.addDialog.formModel,
                relStoreId: 'isnull',
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.addDialog.tableData = res.data;
                this.addDialog.pagination = res.pagination;
            });
        },
        // 重置
        onResetDeviceAddDialog() {
            this.$refs.addDialogQueryForm.resetFields();
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onAddDialogSelectionChange(val) {
            this.addDialog.currentTableSelect = val;
        },
        // 保存关联设备
        onAddDialogConfirm() {
            const list = [];
            this.addDialog.currentTableSelect.forEach(item => {
                list.push(item.id);
            });
            this.$api.Sp.Device.relationDevice({
                storeId: this.queryFormModel.storeId,
                deviceIds: list.join(','),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
                this.addDialog.isVisible = false;
            });
        },
        // 取消关联
        cancelRelationDevice(id) {
            this.$api.Sp.Device.cancelRelationDevice({
                id,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery();
            });
        },
        onAddDialogCancel() {
            this.addDialog.isVisible = false;
        },
        onShowShareInfo(row) {
            const { formModel } = this.shareInfoDialog;
            this.$utils.formModelMerge(formModel, row);
            this.shareInfoDialog.isVisible = true;
        },
        onShareInfoDialogCancel() {
            const { shareInfoForm } = this.$refs;
            shareInfoForm.resetFields();
            const { formModel } = this.shareInfoDialog;
            formModel.id = '';
            this.shareInfoDialog.isVisible = false;
        },
    },
    created() {
        this.queryFormModel.storeId = this.$route.params.storeId;
    },
};
</script>

<style lang="scss">
</style>
